<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    :max-width="900"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal(false)"
  >
    <v-form
      @submit.prevent="execute"
      ref="form"
      lazy-validation
      v-model="valid"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Visualizar integração</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="closeModal(false)">
            <v-icon>fa-solid fa-xmark</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="b-dialog--content">
          <BaseCode :detail="detail" />
        </v-card-text>
        <v-card-actions class="b-dialog--actions" v-if="situation === 'Erro'">
          <BaseButton
            type="submit"
            color="primary"
            :disabled="!valid"
            title="Reprocessar"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { IntegrationService } from '@/services/api/integration';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    integrationId: {
      type: String,
      required: true
    },
    situation: {
      type: String,
      required: true
    }
  },

  data: () => ({
    valid: true,
    detail: {
      id: null,
      movementIntegrationId: null,
      requestObject: null,
      responseObject: null
    }
  }),

  created() {
    this.search();
  },

  methods: {
    async search() {
      try {
        const integrationService = new IntegrationService();
        const { status, data } = await integrationService.getMovementDetail(
          this.integrationId
        );

        if (status === 200 || status === 204) {
          this.detail = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async execute() {
      if (!this.$refs.form.validate(true)) {
        return;
      } else {
        try {
          const integrationService = new IntegrationService();
          const { status } = await integrationService.reprocessIntegration(
            this.integrationId
          );

          if (status === 200 || status === 204) {
            showMessage('success', 'Operação realizada com sucesso');
            this.closeModal(true);
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    },

    closeModal(params) {
      this.$emit('closeModal', params);
    }
  }
};
</script>
